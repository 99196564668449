import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/zeit/54654bf8/website/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { useState } from 'react';
import { Playground } from 'docz';
import { DateFormat, ToUTC, TimeFormat, DateRange } from '@mini-code/base-func/datetime-helper';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "datetime--时间处理"
    }}>{`Datetime / 时间处理`}</h1>
    <blockquote>
      <p parentName="blockquote">{`由于浏览器对于 JS 时间对象的支持有缺陷，basic-helper 提供统一的时间处理方式，包括`}</p>
    </blockquote>
    <ul>
      <li parentName="ul">{`日期格式化`}</li>
      <li parentName="ul">{`时间格式化`}</li>
      <li parentName="ul">{`UTC 标准时间转换`}</li>
      <li parentName="ul">{`生产时间范围`}</li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { DateFormat, ToUTC, TimeFormat, DateRange } from 'basic-helper/datetime-helper';
`}</code></pre>
    <h2 {...{
      "id": "dateformat"
    }}>{`DateFormat`}</h2>
    <Playground __position={0} __code={'() => {\n  const [now, setDate] = useState(Date.now())\n  return (\n    <div>\n      <div>默认时间: {DateFormat(now)}</div>\n      <hr />\n      <div>时分秒: {DateFormat(now, \'YYYY-MM-DD hh:mm:ss\')}</div>\n    </div>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      useState,
      Playground,
      DateFormat,
      ToUTC,
      TimeFormat,
      DateRange
    }} mdxType="Playground">
  {() => {
        const [now, setDate] = useState(Date.now());
        return <div>
          <div>默认时间: {DateFormat(now)}</div>
          <hr />
          <div>时分秒: {DateFormat(now, 'YYYY-MM-DD hh:mm:ss')}</div>
        </div>;
      }}
    </Playground>
    <h2 {...{
      "id": "toutc"
    }}>{`ToUTC`}</h2>
    <p>{`转换到标准 UTC 时间格式`}</p>
    <Playground __position={1} __code={'() => {\n  const [now, setDate] = useState(Date.now())\n  {\n    /* setInterval(() => {\n      setDate(Date.now());\n    }, 1000) */\n  }\n  return (\n    <div>\n      <div>默认: {ToUTC(now)}</div>\n    </div>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      useState,
      Playground,
      DateFormat,
      ToUTC,
      TimeFormat,
      DateRange
    }} mdxType="Playground">
  {() => {
        const [now, setDate] = useState(Date.now());
        {
          /* setInterval(() => {
           setDate(Date.now());
          }, 1000) */
        }
        return <div>
          <div>默认: {ToUTC(now)}</div>
        </div>;
      }}
    </Playground>
    <h2 {...{
      "id": "timeformat"
    }}>{`TimeFormat`}</h2>
    <Playground __position={2} __code={'() => {\n  const [now, setDate] = useState(new Date().getSeconds())\n  setInterval(() => {\n    setDate(new Date().getSeconds())\n  }, 1000)\n  return (\n    <div>\n      <div>默认: {JSON.stringify(TimeFormat(now))}</div>\n      <hr />\n      <div>输出字符串: {TimeFormat(now, true)}</div>\n    </div>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      useState,
      Playground,
      DateFormat,
      ToUTC,
      TimeFormat,
      DateRange
    }} mdxType="Playground">
  {() => {
        const [now, setDate] = useState(new Date().getSeconds());
        setInterval(() => {
          setDate(new Date().getSeconds());
        }, 1000);
        return <div>
          <div>默认: {JSON.stringify(TimeFormat(now))}</div>
          <hr />
          <div>输出字符串: {TimeFormat(now, true)}</div>
        </div>;
      }}
    </Playground>
    <h2 {...{
      "id": "daterange"
    }}>{`DateRange`}</h2>
    <p>{`生产时间范围`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const options = {
  format: 'YYYY-MM-DD',
  extendFormat: ['00:00:00', '23:59:59'],
  toUTC: true
};
DateRange(startDayOffset = 10, endDayOffset = 0, options)
`}</code></pre>
    <Playground __position={3} __code={'() => {\n  return (\n    <div>\n      <div>默认: {JSON.stringify(DateRange())}</div>\n      <hr />\n      <div>\n        不输出 UTC 时间:{\' \'}\n        {JSON.stringify(\n          DateRange(10, 0, {\n            toUTC: false,\n          }),\n        )}\n      </div>\n    </div>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      useState,
      Playground,
      DateFormat,
      ToUTC,
      TimeFormat,
      DateRange
    }} mdxType="Playground">
  {() => {
        return <div>
          <div>默认: {JSON.stringify(DateRange())}</div>
          <hr />
          <div>不输出 UTC 时间: {JSON.stringify(DateRange(10, 0, {
              toUTC: false
            }))}</div>
        </div>;
      }}
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      